import React, { FC } from "react";

import './SectionTitle.css'

export type SectionTitleProps = {
    title: string,
    id?: string,
    isRight?: boolean,
    portraitOnly?: boolean
}

const SectionTitle: FC<SectionTitleProps> = ({ title, id, isRight, portraitOnly }) => {
    return (
        <>
            {id && <div id={id} className={`s-anchor${portraitOnly ? ' pr' : ''}`} />}
            <div className={`title-flex${isRight ? ' inverse-d' : ''}${portraitOnly ? ' pr' : ''}`}>
                <hr />
                <h1 className="text-white s-title heading-text">{title}</h1>
                <hr />
            </div>
        </>
    )
}

export default SectionTitle;