import React, { ImgHTMLAttributes, useState, FC, useEffect } from "react";

export type LazyImageHandle = {
    imageLoaded?: boolean
} & ImgHTMLAttributes<HTMLImageElement>

const LazyImage: FC<LazyImageHandle> = ({ imageLoaded, ...props }) => {
    const [loaded, setLoaded] = useState<Boolean>(false)

    let { src, alt, ...insertProps } = props;
    alt = alt ?? 'lazy-image';
    useEffect(() => {
        if (imageLoaded)
            setLoaded(true)
    }, [imageLoaded])

    return <img src={loaded ? src : ''} alt={alt} {...insertProps} />
}

export default LazyImage;