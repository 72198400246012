import axios from "axios";

// const url = 'https://nazar.sysecobud.kyiv.ua/action.php'
const url = './action.php';
const necessary = `Заявка із сайту\nФорма: `

type FormType = 'calc' | 'feedback' | 'contacts'
export type OrderCall = { phoneNumber: string, comment?: string, color?: string, form: FormType }


type Mapping = {
    [key in FormType]: string;
}

const mapping: Mapping = {
    calc: 'Калькулятор',
    contacts: 'Контакти',
    feedback: 'Відгуки'
}

type OrderCallBody = {
    phoneNumber: string,
    comment: string,
    color?: string
}

export const postOrderCall = (data: OrderCall) => {
    let local: OrderCallBody = {
        phoneNumber: data.phoneNumber,
        color: data.color,
        comment: necessary + mapping[data.form] + '\n' + data.comment,
    }
    //return new Promise<any>(res => setTimeout(() => { res('result') }, 5000))
    return axios.post(url, local);
};