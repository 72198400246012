import { useEffect, useRef } from 'react';


export type RepeatHookReturns = {
    restart: () => void
}

export const useRepeat: (repeatFunction: () => void, duration?: number) => RepeatHookReturns = (callback, delay = 5000) => {
    const savedCallback = useRef<() => void>();
    const intervalId = useRef<NodeJS.Timeout>()

    useEffect(() => {
        savedCallback.current = callback
        intervalId.current = setInterval(callback, delay)
        return () => clearInterval(intervalId.current)
    })
    function restart() {
        
        clearInterval(intervalId.current)
        if (savedCallback.current){
            intervalId.current = (setInterval(savedCallback.current, delay))
        }
    }
    return {
        restart
    }
}