import { useEffect, useState } from "react";
import { OrderCall, postOrderCall } from "../../api/index.ts";


export default function useSubmitWrapper(): {
    setDataAndFetch: React.Dispatch<React.SetStateAction<OrderCall | undefined>>,
    submitting: boolean,
    result: any,
    clearResult: () => void
} {
    const [data, setData] = useState<OrderCall>()
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [result, setResult] = useState<number | 'error' | null>(null)

    useEffect(() => {

        if (data) {
            setResult(null)
            setSubmitting(true)
            postOrderCall(data).then(response => setResult(response.status)).catch(error =>{
                if(error.status){
                    setResult(error.status)
                }
                else
                {
                    setResult('error')
                }
            })
        }
    }, [data])

    useEffect(() => {
        if (result) {
            setSubmitting(false)
            setData(undefined)
        }
    }, [result])

    return {
        setDataAndFetch: setData,
        submitting,
        result,
        clearResult() {
            setResult(undefined)
        }
    }
}