import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import './LangSelector.css'

export default function LangSelector() {
    const location = useLocation();
    const navigate = useNavigate()

    return (
        <p className="text-center">
            <span className={"lang" + (location?.pathname === '/ru' ? '' : ' orange')} onClick={() => { navigate('/ua') }}>UA</span>
            /
            <span className={"lang" + (location?.pathname === '/ru' ? ' orange' : '')} onClick={() => { navigate('/ru') }}>RU</span>
        </p>)

}