import React, { useContext } from "react";
import SectionTitle from "../SectionTitle/SectionTitle.tsx";

import langDictionary from '../../../constants/uaLocal.tsx'

import './AboutUs.css'
import { Waypoint } from "react-waypoint";
import { useLinkStore } from "../../../storage/activeLink.ts";
import LangContext from "../Contexts/LangContext.tsx";

const AboutUs = () => {
    const store = useLinkStore()
    const lang = useContext(LangContext)
    
    return (
        <>
            <Waypoint bottomOffset={store.bottomOffset} topOffset={store.topOffset} onPositionChange={(args) => store.onPositionChange(args, "about")} fireOnRapidScroll>
                <div>
                    <SectionTitle title={langDictionary[lang].ABOUT_US_TITLE} id="about" />
                    <div className="about-holder">
                        <p className="text-white about-text">
                            {langDictionary[lang].ABOUT_US_1}
                            <br />
                            <br />
                            {langDictionary[lang].ABOUT_US_2}
                            <br />
                            <br />
                            {langDictionary[lang].ABOUT_US_3}
                            <br />
                            <br />
                            <span className="about-underline">{langDictionary[lang].ABOUT_US_4}</span>
                        </p>
                    </div>
                </div>
            </Waypoint>
        </>
    )
}

export default AboutUs;