import React, { CSSProperties, FC, ReactNode, useContext } from "react";
import { Accordion, AccordionContext, useAccordionButton } from "react-bootstrap";

import './CustomAccordion.css'

type CustomToggleAccordionProps = {
    children?: React.ReactNode,
    eventKey: string,
    number: number,
    header: string,
    style?: CSSProperties,
    className?: string,
    callback?: (key: string) => void
}

export const CustomAccordionItem: FC<CustomToggleAccordionProps> = ({ children, eventKey, number, header, style, className, callback }) => {
    const { activeEventKey } = useContext(AccordionContext);

    const isCurrentEventKey = activeEventKey?.includes(eventKey);
    const clickArea = useAccordionButton(eventKey, () => callback && callback(eventKey))

    return (
        <div className={"step-holder " + (className ? className : '')} style={style}>
            <div className="custom-accordion-header"  onClick={clickArea}>
                <p>
                    <span className="number">{number}</span>
                    {header}
                </p>
                <div className="open-button" style={{ rotate: isCurrentEventKey ? "180deg" : "0deg" }}>
                    <i className="bi bi-chevron-down" />
                </div>
            </div>
            <Accordion.Collapse eventKey={eventKey}>{<p>{children}</p>}</Accordion.Collapse>
        </div>
    )
}

export const CustomAccordion = ({ children, style }: { children: ReactNode, style?: CSSProperties }) => {
    return <Accordion className="custom-accordion" defaultActiveKey={[]} alwaysOpen style={style}>{children}</Accordion>
}