import { useContext, useEffect, useState } from "react"
import LangContext from "./Contexts/LangContext.tsx"
import langDictionary from "../../constants/uaLocal.tsx";

export type ids = '#our-examples' | '#about' | '#' | '#materials' | "#feedback" | "#partners" | '#contacts';

export default function useNavItems() {
    
    const lang = useContext(LangContext)

    const [items, setItems] = useState<Array<Array<string | ids>>>([
        [langDictionary[lang].NAV_OUR_EXAMPLES, '#our-examples'],
        [langDictionary[lang].NAV_ABOUT_US, '#about'],
        [langDictionary[lang].NAV_MATERIALS, '#materials'],
        [langDictionary[lang].NAV_PARTNERS, '#partners'],
        [langDictionary[lang].NAV_FEEDBACK, "#feedback"],
        [langDictionary[lang].NAV_CONTACTS, '#contacts']
    ])

    useEffect(() => {
        setItems([
            [langDictionary[lang].NAV_OUR_EXAMPLES, '#our-examples'],
            [langDictionary[lang].NAV_ABOUT_US, '#about'],
            [langDictionary[lang].NAV_MATERIALS, '#materials'],
            [langDictionary[lang].NAV_PARTNERS, '#partners'],
            [langDictionary[lang].NAV_FEEDBACK, "#feedback"],
            [langDictionary[lang].NAV_CONTACTS, '#contacts']
        ])
    }, [lang])

    return items;
}