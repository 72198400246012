import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useSwipeable } from "react-swipeable";

import './ExamplesSlider.css'

import example01 from '../../../images/examples/example01.jpg'
import example02 from '../../../images/examples/example02.jpg'
import example03 from '../../../images/examples/example03.jpg'
import example04 from '../../../images/examples/example04.jpg'
import example05 from '../../../images/examples/example05.jpg'
import example06 from '../../../images/examples/example06.jpg'
import example07 from '../../../images/examples/example07.png'
import example08 from '../../../images/examples/example08.png'
import example09 from '../../../images/examples/example09.jpg'
import example10 from '../../../images/examples/example10.jpg'
import example11 from '../../../images/examples/example11.jpg'
import example12 from '../../../images/examples/example12.jpg'
import example13 from '../../../images/examples/example13.jpg'
import example14 from '../../../images/examples/example14.jpg'
import LazyImage from "../LazyImage.tsx";
import ImageButton from "../ImageButtons/ImageButton.tsx";
import { useRepeat } from "../ReapeatFunction.tsx";

const images = [example01, example02, example03, example04, example05, example06, example07, example08, example09, example10, example11, example12, example13, example14]

export type LeftRightHandle = {
    clickLeft: () => void,
    clickRight: () => void,
}

const ExamplesSlider = forwardRef<LeftRightHandle>((props, ref) => {
    const [counter, setCounter] = useState<number>(0)

    useImperativeHandle(ref, () => {
        return {
            clickLeft: onLeftClick,
            clickRight: onRightClick
        }
    }, [])

    const onLeftClick = () => {
        setCounter(state => { if (state <= 0) return 12; return --state })
        restart()
    }
    const onRightClick = () => {
        setCounter(state => { if (state >= 13) return 0; return ++state })
        restart()
    }
    const handlers = useSwipeable({
        onSwipedLeft: onRightClick,
        onSwipedRight: onLeftClick,
        swipeDuration: 2000,
    })

    const { restart } = useRepeat(() => setCounter(state => { if (state >= 13) return 0; return ++state }), 5000)

    return (
        <div className="sliders-holder" {...handlers}>
            <div className="s-buttons-holder">
                <ImageButton small background="opaque" type="left" onClick={onLeftClick} />
                <ImageButton small background="opaque" type="right" onClick={onRightClick} />
            </div>
            <div className={`inner-slider-holder sl-h-${counter}`}>
                {images.map((img, id) => {
                    return (
                        <div
                            key={id}
                            id={`example${id}`}
                            className={"ex-img-holder"}>
                            <LazyImage src={img} alt={`example${id}`} imageLoaded={id <= counter * 2 + 2} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
})

export default ExamplesSlider;