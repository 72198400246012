import React from "react";


const GoogleMaps = () => {
    return (
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2543.1819443379054!2d30.630015399999998!3d50.4004467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4c5ad9ab26d6d%3A0x323a054061a58637!2sSribnokilska%20St%2C%203%D0%92%2C%20Kyiv%2C%2002068!5e0!3m2!1sen!2sua!4v1713783787258!5m2!1sen!2sua"
            width="100%"
            height="100%"
            style={{ border: "solid 3px var(--main-color)", borderRadius: 1}}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade" />
    )
}

export default GoogleMaps;