import React, { useContext, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import SectionTitle from "../SectionTitle/SectionTitle.tsx";
import langDictionary from "../../../constants/uaLocal.tsx";

import './Certificates.css'

import cert1 from "../../../images/certificates/cert1.png"
import cert2 from "../../../images/certificates/cert2.png"
import cert3 from "../../../images/certificates/cert3.png"
import cert4 from "../../../images/certificates/cert4.png"
import FunctionsContext from "../Contexts/FunctionsContext.tsx";
import ImageButton from "../ImageButtons/ImageButton.tsx";
import LangContext from "../Contexts/LangContext.tsx";

const Certificates = () => {
    const imgRef = useRef<HTMLImageElement>(null)
    const backdropRef = useRef<HTMLDivElement>(null)
    const [show, setShow] = useState<boolean>(false)
    const [shownImage, setShownImage] = useState<string | null>(null)
    const lang = useContext(LangContext)

    const funcs = useContext(FunctionsContext)

    const onShow = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        setShownImage(e.currentTarget.src)
        setShow(true)
    }
    const onHide = () => {
        if (imgRef.current) {
            setShow(false)
            funcs?.enableScroll();
        }
    }
    const onEnter = () => {
        if (imgRef.current && shownImage) {
            let src = shownImage
            imgRef.current.src = src;
            funcs?.disableScroll();
        }
    }

    return (
        <>
            <SectionTitle title={langDictionary[lang].CERTIFICATES_TITLE} isRight />
            <div className="cert-holder">
                <img className="cert" src={cert1} alt='certificate 1' onClick={onShow} />
                <img className="cert" src={cert2} alt='certificate 2' onClick={onShow} />
                <img className="cert" src={cert3} alt='certificate 3' onClick={onShow} />
                <img className="cert" src={cert4} alt='certificate 4' onClick={onShow} />
            </div>
            <CSSTransition in={show} unmountOnExit timeout={500} nodeRef={backdropRef} onEnter={onEnter} classNames={'cert'}>
                <div className="cert-backdrop" ref={backdropRef} data-bs-theme="dark">
                    <div style={{ margin: 'auto 0' }}>
                        <TransformWrapper doubleClick={{mode: "toggle", step:4}} centerOnInit centerZoomedOut maxScale={8}>
                            {({ zoomIn, zoomOut, resetTransform }) =>
                                <>
                                    <div style={{ zIndex:3, position: 'absolute', display: "flex", bottom: 0, left: '50%', translate: '-50% 0' }}>
                                        <ImageButton type="plus" onClick={() => zoomIn()} style={{ margin: '1vmax' }} small background="opaque" />
                                        <ImageButton type="minus" onClick={() => zoomOut()} style={{ margin: '1vmax' }} small background="opaque" />
                                        <ImageButton type="zoom-out" onClick={() => resetTransform()} style={{ margin: '1vmax' }} small background="opaque" />
                                    </div>
                                    <TransformComponent>
                                        <img className="cert-full" alt="certificates holder" ref={imgRef} />
                                    </TransformComponent>
                                </>
                            }
                        </TransformWrapper>
                    </div>
                    <i className="bi bi-x-lg position-absolute text-white" onClick={onHide} style={{ top: 0, right: 0, width: '6vh', height: '5vh', fontSize: '5vh', cursor: 'pointer', textShadow: '1px 1.5px 3px black' }} color="light" />
                </div>
            </CSSTransition>
        </>
    )
}

export default Certificates;