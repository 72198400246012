import React, { ButtonHTMLAttributes, FC } from "react";

import './ImageButton.css'

type ImageButtonProps = {
    small?: boolean,
    background?: 'transparent' | 'opaque' | 'dark',
    type: 'left' | 'right' | 'plus' | 'minus' | 'zoom-out'
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'>

const ImageButton: FC<ImageButtonProps> = ({ background = 'transparent', small = false, type, ...props }) => {
    let additionalClasses = ' ' + (props.className ?? '');
    let typeClass = "";
    switch (type) {
        case 'left':
            typeClass = 'bi-chevron-left'
            break;
        case 'right':
            typeClass = 'bi-chevron-right';
            break;
        case 'minus':
            typeClass = 'bi-dash-lg'
            break;
        case 'plus':
            typeClass = 'bi-plus-lg'
            break;
        case 'zoom-out':
            typeClass = 'bi-arrows-fullscreen'
            break;
    }
    if (background === "opaque") {
        additionalClasses += ' image-button-opaque'
    } if (background === 'dark') {
        additionalClasses += ' image-button-dark'
    }
    if (small) {
        additionalClasses += ' image-button-small'
    }
    return <button className={`image-button${additionalClasses}`} disabled={props.disabled} onClick={props.onClick} {...props}>
        <i className={`bi ${typeClass}`} />
    </button>
}

export default ImageButton