import { create } from "zustand";

import img1 from '../images/image1.png'
import img2 from '../images/image2.png'
import img3 from '../images/image3.png'
import img4 from '../images/image4.png'
import img5 from '../images/image5.png'

type SliderStoreType = {
    images: Array<string>
    selectedIndex: number
    maxIndex: number
    increment: () => void
    decrement: () => void
    setMaxIndex: (value: number) => void
}

const loadedImages = [img1, img2, img3, img4, img5]
const images = (loadedImages.length === 1 || loadedImages.length > 3) ? [...loadedImages] : [...loadedImages, ...loadedImages, ...loadedImages]

export const useSliderStore = create<SliderStoreType>((set) => ({
    images: images,
    selectedIndex: 0,
    maxIndex: images.length - 1,
    increment: () => set(state => {
        if (state.selectedIndex < state.maxIndex)
            return ({ selectedIndex: state.selectedIndex + 1 })
        return { selectedIndex: 0 }
    }),
    decrement: () => set(state => {
        if (state.selectedIndex > 0)
            return { selectedIndex: state.selectedIndex - 1 }
        return { selectedIndex: state.maxIndex }
    }),
    setMaxIndex: (value) => set(() => ({ maxIndex: value }))
}))