import React from "react";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        href=""
        ref={ref}
        className="d-flex justify-content-between p-0 m-0 h-100 align-items-center"
        style={{ cursor: "pointer", userSelect: 'none' }}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <p style={{
            margin: 0,
            fontFamily: 'var(--open-sans)',
            fontSize: '3vh',
        }}>{children}</p>
        <i className="bi bi-chevron-down" style={{ color: 'var(--main-color)', fontSize: '3vh' }} />
    </div>
));

export default CustomToggle;