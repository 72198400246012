import { Waypoint } from "react-waypoint";
import { create } from "zustand";

export type LinkElement = 'examples' | 'about' | 'materials' | 'partners' | 'feedback' | 'contacts' | 'none'
type ActiveLinkStore = {
    currentLink: LinkElement,
    bottomOffset: string,
    topOffset: string,
    onPositionChange: (args: Waypoint.CallbackArgs, name: LinkElement) => void
}

export const useLinkStore = create<ActiveLinkStore>((set) => ({
    currentLink: 'none',
    bottomOffset: '50%',
    topOffset: '35%',
    onPositionChange: (args, name) => set(state => {
        if (args.currentPosition === 'inside') { return { currentLink: name } }
        if ((args.currentPosition === 'above' || args.currentPosition === 'below') && state.currentLink === name) {
            return { currentLink: 'none' }
        }
        return {}
    })
}))