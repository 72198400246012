import React, { FC, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

import './CustomNavbar.css'
import './Popup.css'
import './CustomNavDropDown.css'
import img from '../../../images/logo.svg'
import { useSwipeable } from "react-swipeable";
import { LinkElement, useLinkStore } from "../../../storage/activeLink.ts";
import phoneNumbers from "../../../constants/phoneNumbers.ts";
import useNavItems, { ids } from "../useNavItems.tsx";
import LangSelector from "../LangSelector/LangSelector.tsx";

type Mapping = {
    [key in LinkElement]: ids;
}
const mapping: Mapping = {
    about: "#about",
    contacts: "#contacts",
    examples: "#our-examples",
    feedback: "#feedback",
    materials: "#materials",
    partners: "#partners",
    none: '#'
}
const NavbarItems: FC<{ onClick?: () => void }> = ({ onClick }) => {

    const items = useNavItems()


    const store = useLinkStore()
    return <>
        {items.map((item) => (
            <a key={item[1]} href={item[1]} className={"link-unstyled" + (mapping[store.currentLink] === item[1] ? ' current' : '')} onClick={onClick}>
                <div className="under-link" />
                <p className="text-center">{item[0]}</p>
            </a>
        ))}
    </>
}

const PhoneNumbersDropDown = () => {
    return (
        <div className="phone-number" >

            {/* <div className="popup" >
                {phoneNumbers.map((number, i) => (
                    <a key={i} href={`tel:${number}`} style={{ animationDelay: `${0.1 * i}s` }}>
                        <p>
                            {number}{' '}
                            <i className="bi bi-chevron-down" />
                        </p>
                    </a>
                ))}
            </div> */}
            {phoneNumbers.map((pn) => <a key={pn} className="link" href={`tel:${pn}`}><span>{pn}</span></a>)}
            <div className="popup-n">
                <i className="bi bi-telephone-forward">{'\t\t\t'}</i>
                <div>
                    {phoneNumbers.map((pn) => <a key={pn} className="link" href={`tel:${pn}`}><span>{pn}</span></a>)}
                </div>
            </div>
            {/* <i className="bi bi-chevron-down" >{'\t\t\t'}</i> */}
        </div>
    )
}

const CustomNavbar = () => {
    const uaData = {
        graph: 'Графік Роботи:',
        graphRange: 'Пн-Нд з 9:00 до 19:00',
    }
    const handlers = useSwipeable({
        onSwipedRight: () => { setShow(false) },
        swipeDuration: 2000,
    })
    const [show, setShow] = useState<boolean>(false)




    return (<>
        <nav className="custom-navbar">
            <img src={img} className="logo" alt="SteliOK logo" onClick={() => window.location.href = "#"} />
            <div className="nav-head">
                <div className="nav-items">
                    <NavbarItems />
                </div>
                <div className="nav-items">
                    <div className="link-unstyled">
                        <PhoneNumbersDropDown />
                    </div>
                    <div className="link-unstyled"><LangSelector /></div>
                </div>
            </div>
            <div className="nav-burger" onClick={() => { setShow(!show) }} />
        </nav>
        <Offcanvas show={show} data-bs-theme="dark" onHide={() => setShow(false)} placement="end">
            <Offcanvas.Header>
                <img src={img} className="off-pt" alt="SteliOK logo" />
            </Offcanvas.Header>
            <Offcanvas.Body className="p-0" {...handlers}>
                <LangSelector />
                <div className="offcanv-body off-pt">
                    <div className="offcanvas-close py-5" onClick={() => setShow(false)} >
                        <i className="bi bi-chevron-right" />
                    </div>
                    <div className="navigations">
                        <NavbarItems onClick={() => setShow(false)} />
                        <div className="data">
                            <p className="d-head">{uaData.graph}</p>
                            <p className="d-range">{uaData.graphRange}</p>
                            <div className="d-flex flex-column">
                                {phoneNumbers.map((number, i) => (
                                    <a key={i} href={`tel:${number}`}><p>{number}</p></a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>
    )
}

export default CustomNavbar;