const langDictionary = {
    ua: {

        RESPONSE_SENT_TITLE: `Ваш запит надіслано`,
        RESPONSE_SENT_INFO: `Наш менеджер скоро з вами зв'яжеться`,
        RESPONSE_ERROR: `Помилка при відправленні запиту`,

        NAV_OUR_EXAMPLES: `Наші роботи`,
        NAV_ABOUT_US: `Про нас`,
        NAV_MATERIALS: `Матеріали`,
        NAV_PARTNERS: `Партнери`,
        NAV_FEEDBACK: `Відгуки`,
        NAV_CONTACTS: `Контакти`,
        MAIN_TOP: 'Натяжні',
        MAIN_BOTTOM: 'Стелі',
        MAIN_SUB_TOP: 'стиль, який ніколи',
        MAIN_SUB_BOTTOM: 'не виходить з моди',
        MAIN_SLIDER_TEXT: 'Усі фото на сайті являються нашими роботами',
        OUR_EXAMPLES_TITLE: 'Наші роботи',
        OUR_EXAMPLES_TEXT: 'Пориньте у світ вишуканих натяжних стель: перегляньте наші проекти, кожен з яких - витвір мистецтва.',
        DISCOUNT_TITLE: `Кожна наступна кімната дешевше за попередню`,
        DISCOUNT_ROOM: ` кімната`,
        ABOUT_US_TITLE: `Про нас`,
        ABOUT_US_1: `Наша команда Ecosteli більше 10 років займається розробкою, виробництвом і постачанням натяжних стель, деталей і аксесуарів для їх встановлення.`,
        ABOUT_US_2: `Ми є офіційними представниками всесвітньо відомих виробників PANIFEL (Франція), KOREA PLAST (Корея), ECOPLASTIKS (БЕЛЬГІЯ) та TEQTUM (Німеччина) в Україні.`,
        ABOUT_US_3: `Відкритість до нових ідей, пошук цікавих дизайнерських і технічних рішень, регулярний моніторинг системи управління якістю, постійне відстеження нових тенденцій ринку, зосередженість на наданні якісного сервісу нашим клієнтам - це лише частина завдань, з якими щодня працює наша команда.`,
        ABOUT_US_4: `Ecosteli - від найсміливішої ідеї до успішно реалізованого проєкту.`,
        CERTIFICATES_TITLE: `Наші сертифікати`,
        MATERIALS_TITLE: `Матеріали`,
        WHY_TITLE: `Чому варто працювати саме з нами?`,
        WHY_B1_TITLE: `Довіра`,
        WHY_B2_TITLE: `Якість`,
        WHY_B3_TITLE: `Знижка`,
        WHY_B1_SUB: `Більше 4000 задоволених клієнтів`,
        WHY_B2_SUB: `Приділяємо увагу кожній деталі`,
        WHY_B3_SUB: `Отримайте знижку до 30%`,


        GLOSSY: `Глянцева`,
        SATIN: `Сатинова`,
        MAT: `Матова`,
        GLOSSY_MATERIAL: `Глянцеві натяжні стелі - це стильний та сучасний вибір для оздоблення інтер'єру. Блискуча поверхня надає приміщенню сучасний та елегантний вигляд, створюючи відчуття простору та розкіші. Блискуча поверхня відбиває світло, що дозволяє візуально збільшити розмір кімнати.`,
        SATIN_MATERIAL: `Сатинові стелі мають приглушений, дискретний блиск, який створює атмосферу комфорту і затишку. Текстура сатину дозволяє розсіювати світло, що створює м'яке та рівномірне освітлення в приміщенні. Це ідеальний вибір для тих, хто шукає золоту середину між глянцевими та матовими поверхнями`,
        MAT_MATERIAL: `Матові натяжні стелі - це ідеальне поєднання естетики та практичності. Вони ідеально підходять для будь-яких приміщень - від житлових до комерційних, додаючи їм елегантності та затишку. Характеризується приглушеним, немерехтливим відблиском, що створює приємний затишок у приміщенні.`,

        MORE: `Більше`,

        CALCULATOR: `Прорахунок`,
        CALCULATOR_INFO: `ОБЕРІТЬ ФАКТУРУ ТА КОЛІР, ТА ЗАЛИШАЙТЕ ЗАЯВКУ НА ПРОРАХУНОК В ЗРУЧНИЙ ДЛЯ ВАС МЕСЕНДЖЕР:`,
        CALC_FACTURE: `Фактура`,
        CALC_COLOR: `Колір`,
        CALC_AREA: `Площа`,
        CALC_AREA_LONG: `Вкажіть площу вашої кімнати:`,
        CALC_MESSANGER: `Месенджер`,
        CALC_MESSANGER_LONG: `Оберіть месенджер:`,
        CALC_GET_CALL: `Замовити дзвінок`,

        YOUR_PHONE: `Ваш телефон`,
        WRONG_NUMBER: `Введіть номер формату +380#########`,

        SPECIALS_TITLE: `Спеціальні умови для партнерів`,
        SPECIALS_FIRST: `Ви дзвоните або залишаєте заявку на сайті`,
        SPECIALS_SECOND: `Наш менеджер зв’язується з вами та розповідає про умови співпраці`,
        SPECIALS_THIRD: `Ми обговорюємо усі умови і ви стаєте нашим новим партнером`,
        SPECIALS_FOURTH: `Ми вам надаєм каталог з нашими позиціями товару`,

        ADVANTAGES_TITLE: `Переваги`,
        SIX_REASONS_1: `причин замовити монтаж`,
        SIX_REASONS_2: `натяжної стелі в `,

        ADV_1_TITLE: `Екологічно чисті матеріали`,
        ADV_1_TEXT: `Ми використовуємо лише екологічно чисті та високоякісні матеріали, гарантуючи довговічнітсь та безпеку Вашому здоров’ю`,
        ADV_2_TITLE: `Натяжні стелі будь-якої складності`,
        ADV_2_TEXT: `Різноманітний вибір варіантів натяжної стелірізної складності, що дозволяє підібрати оптимальний варіант під будь-який дизайн`,
        ADV_3_TITLE: `Найнижчі ціни на ринку України`,
        ADV_3_TEXT: `Будучи офіційними представниками виробників в Україні, ми гарантуємо Вам найкращі ціни без додаткових націнок`,
        ADV_4_TITLE: `Сертифіковані монтажники`,
        ADV_4_TEXT: `Усі наші монтажники пройшли спеціальне навчання та мають ліцензію. Ви можете бути впевненні, що ваша стеля буде ідеальною і без зволікань`,
        ADV_5_TITLE: `Ідеальна поверхня`,
        ADV_5_TEXT: `Натяг спеціальної ПВХ-плівки дозволяє створити ідеально рівну поверхню. Це найпростіший і доступний спосіб отримати рівну стелю.`,
        ADV_6_TITLE: `Вологозахист`,
        ADV_6_TEXT: `Натяжні стелі відзначаються високим вологозахистом, що робить їх ідеальним вибором для вологих приміщень. Їх конструкція забезпечує надійний захист від протікання та утворення конденсату, що забезпечує довговічність і надійність стелі.`,

        ADV_1_SMALL_TEXT: `Ми\u00a0використовуємо лише екологічно чисті та високоякісні матеріали`,
        ADV_2_SMALL_TEXT: `Різноманітний вибір варіантів натяжної стелі різної складності`,
        ADV_3_SMALL_TEXT: `Будучи офіційними дилерами виробників в Україні, ми гарантуємо Вам найкращі ціни`,
        ADV_4_SMALL_TEXT: `Усі наші монтажники пройшли спеціальне навчання та мають ліцензію.`,
        ADV_5_SMALL_TEXT: `Натяг спеціальної ПВХ-плівки дозволяє створити ідеально рівну поверхню`,
        ADV_6_SMALL_TEXT: `Конструкція забезпечує надійний захист від протікання та утворення конденсату`,

        FEEDBACK_TITLE: `Відгуки`,
        FREE_MEASUREMENT: `Безкоштовний замір`,

        STEPS_STITLE: `Етапи роботи`,
        STEPS_SUBTITLE_NORMAL: `Від консультації до `,
        STEPS_SUBTITLE_ORANGE: `готового дизайну`,
        STEPS_FIRST_TITLE: `Консультація з менеджером`,
        STEPS_FIRST_TEXT: `Ви телефонуєте або залишаєте свої контактні дані на нашому веб-сайті, і наш менеджер зв'яжеться з Вами найближчим часом для надання консультації`,
        STEPS_SECOND_TITLE: `Замір`,
        STEPS_SECOND_TEXT: `Домовляємось про безкоштовний замір. Проводимо його`,
        STEPS_THIRD_TITLE: `Договір`,
        STEPS_THIRD_TEXT: `Вибравши бажаний варіант з нашого каталогу, ми обговорюємо всі деталі Вашого дизайну натяжних стель, узгоджуємо усі нюанси і домовляємося про дату монтажу. Після цього укладаємо договір для підтвердження умов співпраці.`,
        STEPS_FOURTH_TITLE: `Монтаж`,
        STEPS_FOURTH_TEXT: `У зазначену у договорі дату ми починаємо монтаж Вашої натяжної стелі. Спеціалізована команда майстрів прибуде до Вас з усім необхідним обладнанням і матеріалами, та розпочне монтаж`,

        AFTERSTEPS_STELIOK_NAME: `Ecosteli`,
        AFTERSTEPS_STELIOK_TEXT: ` - це професійна команда з унікальним досвідом та навичками, дозволяючими створювати та реалізовувати проєкти найвищої якості`,

        AFTERSTEPS_YEARS: `років у будівництві`,
        AFTERSTEPS_CLIENTS: `задоволених клієнтів`,
        AFTERSTEPS_DEALERS: `дилерів по всій країні`,
        AFTERSTEPS_FINISHED: `завершених проєктів`,

        FAQ_TITLE: `Найчастіші питання`,

        FAQ_HEADER_1: "Чи не боїться стеля води?",
        FAQ_TEXT_1: "Ні, не боїться. Конструкція натяжниї стель забезпечує надійний захист від протікання та утворення конденсату, що забезпечує довговічність і надійність стелі.",

        FAQ_HEADER_2: "Із якої плівки виготовляються полотна?",
        FAQ_TEXT_2: "Ми використовуємо ПВХ-плівку.",

        FAQ_HEADER_3: "Чи даєте Ви гарантію?",
        FAQ_TEXT_3: "Так, ми використовуємо матеріали європейської якості та даємо гарантію на наші натяжні стелі - 15 років.",

        FAQ_HEADER_4: "Можливо замовити замір онлайн?",
        FAQ_TEXT_4: "Так, залишаште номер телефону на сайті та наш менеджер зв’яжеться з Вами для консультації та назначення дати заміру, або телефонуйте за номером вказаним на сайті.",

        FAQ_HEADER_5: "Залишається неприємний запах після монтажу",
        FAQ_TEXT_5: "Ми працюємо за технологією “гарячого монтажу” тому одразу після встановлення в кімнаті залишається слабкий запах газу, який ми використовуємо. Щоб позбавитись від нього, достатньо провітрити кімнату протягом години.",

        FAQ_HEADER_6: "З якими виробниками плівки Ви працюєте?",
        FAQ_TEXT_6: "Ми є офіційними представниками всесвітньо відомих виробників PANIFEL (Франція), KOREA PLAST (Корея), ECOPLASTIKS (БЕЛЬГІЯ) та TEQTUM (Німеччина) в Україні.",

        FAQ_HEADER_7: "Як швидко Ви встановлюєте натяжну стелю?",
        FAQ_TEXT_7: "Тривалість установки натяжної стелі залежить від розміру та складності приміщення. Зазвичай встановлення займає від кількох годин до кількох днів. Процес включає підготовчі роботи, розтягування стелі та установку профілів.",

        FAQ_HEADER_8: "Чи не виділяє натяжна стеля шкідливі хімічні речовини?",
        FAQ_TEXT_8: "Оригінальні європейські натяжні стелі не виділяють шкідливих хімічних речовин, оскільки практично не містять фенолу.",

        FAQ_MORE_QUESTIONS: `Більше питань`,
        CONTACTS_TITLE: `Контакти`,
        CONTACTS_TEXT: `Є питання на які не знайшли відповіді? `,
        CONTACTS_TEXT_ORANGE: ` Зателефонуй нам!`,
        CONTACTS_ADRESS: `Срібнокільська, 3В`,
        CONTACTS_BUTTON: `Отримати знижку до 30%`,
    },
    ru: {
        RESPONSE_SENT_TITLE: `Ваш запрос отправлен`,
        RESPONSE_SENT_INFO: `Наш менеджер скоро с вами свяжется`,
        RESPONSE_ERROR: `Ошибка при отправке запроса`,

        NAV_OUR_EXAMPLES: `Наши работы`,
        NAV_ABOUT_US: `О нас`,
        NAV_MATERIALS: `Материалы`,
        NAV_PARTNERS: `Партнёры`,
        NAV_FEEDBACK: `Отзывы`,
        NAV_CONTACTS: `Контакты`,
        MAIN_TOP: 'Натяжные',
        MAIN_BOTTOM: 'Потолки',
        MAIN_SUB_TOP: 'стиль, который никогда',
        MAIN_SUB_BOTTOM: 'не выходит из моды',
        MAIN_SLIDER_TEXT: 'Все фото на сайте являются нашими работами',
        OUR_EXAMPLES_TITLE: 'Наши работы',
        OUR_EXAMPLES_TEXT: 'Окунитесь в мир изысканных натяжных потолков: посмотрите наши проекты, каждый из которых - произведение искусства.',
        DISCOUNT_TITLE: `Каждая следующая комната дешевле предыдущей`,
        DISCOUNT_ROOM: ` комната`,
        ABOUT_US_TITLE: `О нас`,
        ABOUT_US_1: `Наша команда Ecosteli более 10 лет занимается разработкой, производством и поставкой натяжных потолков, деталей и аксессуаров для их установки.`,
        ABOUT_US_2: `Мы являемся официальными представителями всемирно известных производителей PANIFEL (Франция), KOREA PLAST (Корея), ECOPLASTIKS (Бельгия) и TEQTUM (Германия) в Украине.`,
        ABOUT_US_3: `Открытость к новым идеям, поиск интересных дизайнерских и технических решений, регулярный мониторинг системы управления качеством, постоянное отслеживание новых тенденций рынка, сосредоточенность на предоставлении качественного сервиса нашим клиентам - это лишь часть задач, с которыми ежедневно работает наша команда.`,
        ABOUT_US_4: `Ecosteli - от самой смелой идеи до успешно реализованного проекта.`,
        CERTIFICATES_TITLE: `Наши сертификаты`,
        MATERIALS_TITLE: `Материалы`,
        WHY_TITLE: `Почему стоит работать именно с нами?`,
        WHY_B1_TITLE: `Доверие`,
        WHY_B2_TITLE: `Качество`,
        WHY_B3_TITLE: `Скидка`,
        WHY_B1_SUB: `Более 4000 довольных клиентов`,
        WHY_B2_SUB: `Уделяем внимание каждой детали`,
        WHY_B3_SUB: `Получите скидку до 30%`,


        GLOSSY: `Глянцевый`,
        SATIN: `Сатиновый`,
        MAT: `Матовый`,
        GLOSSY_MATERIAL: `Глянцевые натяжные потолки - это стильный и современный выбор для отделки интерьера. Блестящая поверхность придает помещению современный и элегантный вид, создавая ощущение простора и роскоши. Блестящая поверхность отражает свет, что позволяет визуально увеличить размер комнаты.`,
        SATIN_MATERIAL: `Сатиновые потолки имеют приглушенный, дискретный блеск, который создает атмосферу комфорта и уюта. Текстура сатина позволяет рассеивать свет, что создает мягкое и равномерное освещение в помещении. Это идеальный выбор для тех, кто ищет золотую середину между глянцевыми и матовыми поверхностями`,
        MAT_MATERIAL: `Матовые натяжные потолки - это идеальное сочетание эстетики и практичности. Они идеально подходят для любых помещений - от жилых до коммерческих, добавляя им элегантности и уюта. Характеризуется приглушенным, немерцающим бликом, что создает приятный уют в помещении.`,

        MORE: `Больше`,

        CALCULATOR: `Просчет`,
        CALCULATOR_INFO: `ВЫБЕРИТЕ ФАКТУРУ И ЦВЕТ, И ОСТАВЛЯЙТЕ ЗАЯВКУ НА ПРОСЧЕТ В УДОБНЫЙ ДЛЯ ВАС МЕССЕНДЖЕР:`,
        CALC_FACTURE: `Фактура`,
        CALC_COLOR: `Цвет`,
        CALC_AREA: `Площадь`,
        CALC_AREA_LONG: `Укажите площадь вашей комнаты:`,
        CALC_MESSANGER: `Мессенджер`,
        CALC_MESSANGER_LONG: `Выберите мессенджер:`,
        CALC_GET_CALL: `Заказать звонок`,

        YOUR_PHONE: `Ваш телефон`,
        WRONG_NUMBER: `Введите номер формата +380#########`,

        SPECIALS_TITLE: `Специальные условия для партнеров`,
        SPECIALS_FIRST: `Вы звоните или оставляете заявку на сайте`,
        SPECIALS_SECOND: `Наш менеджер связывается с вами и рассказывает об условиях сотрудничества`,
        SPECIALS_THIRD: `Мы обсуждаем все условия и вы становитесь нашим новым партнером`,
        SPECIALS_FOURTH: `Мы вам предоставляем каталог с нашими позициями товара`,

        ADVANTAGES_TITLE: `Преимущества`,
        SIX_REASONS_1: `причин заказать монтаж`,
        SIX_REASONS_2: `натяжной потолок в`,

        ADV_1_TITLE: `Экологически чистые материалы`,
        ADV_1_TEXT: `Мы используем только экологически чистые и высококачественные материалы, гарантируя долговечность и безопасность Вашему здоровью`,
        ADV_2_TITLE: `Натяжные потолки любой сложности`,
        ADV_2_TEXT: `Разнообразный выбор вариантов натяжного потолка различной сложности, что позволяет подобрать оптимальный вариант под любой дизайн`,
        ADV_3_TITLE: `Самые низкие цены на рынке Украины`,
        ADV_3_TEXT: `Быв официальными представителями производителей в Украине, мы гарантируем Вам лучшие цены без дополнительных наценок`,
        ADV_4_TITLE: `Сертифицированные монтажники`,
        ADV_4_TEXT: `Все наши монтажники прошли специальное обучение и имеют лицензию. Вы можете быть уверены, что ваш потолок будет идеальным и без проволочек`,
        ADV_5_TITLE: `Идеальная поверхность`,
        ADV_5_TEXT: `Натяжение специальной ПВХ-пленки позволяет создать идеально ровную поверхность. Это самый простой и доступный способ получить ровный потолок`,
        ADV_6_TITLE: `Влагозащита`,
        ADV_6_TEXT: `Натяжные потолки отличаются высокой влагозащитой, что делает их идеальным выбором для влажных помещений. Их конструкция обеспечивает надежную защиту от протечек и образования конденсата, что обеспечивает долговечность и надежность потолка`,

        ADV_1_SMALL_TEXT: `Мы\u00a0используем только экологически чистые и высококачественные материалы`,
        ADV_2_SMALL_TEXT: `Разнообразный выбор вариантов натяжного потолка различной сложности`,
        ADV_3_SMALL_TEXT: `Являясь официальными дилерами производителей в Украине, мы гарантируем Вам лучшие цены`,
        ADV_4_SMALL_TEXT: `Все наши монтажники прошли специальное обучение и имеют лицензию`,
        ADV_5_SMALL_TEXT: `Натяжение специальной ПВХ-пленки позволяет создать идеально ровную поверхность`,
        ADV_6_SMALL_TEXT: `Конструкция обеспечивает надежную защиту от протечек и образования конденсата`,
        FEEDBACK_TITLE: `Отзывы`,
        FREE_MEASUREMENT: `Бесплатный замер`,

        STEPS_STITLE: `Этапы работы`,
        STEPS_SUBTITLE_NORMAL: `От консультации до`,
        STEPS_SUBTITLE_ORANGE: `готового дизайна`,
        STEPS_FIRST_TITLE: `Консультация с менеджером`,
        STEPS_FIRST_TEXT: `Вы звоните или оставляете свои контактные данные на нашем веб-сайте, и наш менеджер свяжется с Вами в ближайшее время для предоставления консультации`,
        STEPS_SECOND_TITLE: `Замер`,
        STEPS_SECOND_TEXT: `Оговариваем бесплатный замер. Проводим его`,
        STEPS_THIRD_TITLE: `Договор`,
        STEPS_THIRD_TEXT: `Выбрав желаемый вариант из нашего каталога, мы обсуждаем все детали Вашего дизайна натяжных потолков, согласовываем все нюансы и договариваемся о дате монтажа. После этого заключаем договор для подтверждения условий сотрудничества`,
        STEPS_FOURTH_TITLE: `Монтаж`,
        STEPS_FOURTH_TEXT: `В указанную в договоре дату мы начинаем монтаж Вашего натяжного потолка. Специализированная команда мастеров прибудет к Вам со всем необходимым оборудованием и материалами, и начнет монтаж`,

        AFTERSTEPS_STELIOK_NAME: `Ecosteli`,
        AFTERSTEPS_STELIOK_TEXT: ` - это профессиональная команда с уникальным опытом и навыками, позволяющими создавать и реализовывать проекты высочайшего качества`,

        AFTERSTEPS_YEARS: `лет в строительстве`,
        AFTERSTEPS_CLIENTS: `удовлетворенных клиентов`,
        AFTERSTEPS_DEALERS: `дилеров по всей стране`,
        AFTERSTEPS_FINISHED: `завершенных проектов`,

        FAQ_TITLE: `Самые частые вопросы`,

        FAQ_HEADER_1: "Не боится ли потолок воды?",
        FAQ_TEXT_1: "Нет, не боится. Конструкция натяжных потолков обеспечивает надежную защиту от протекания и образования конденсата, что обеспечивает долговечность и надежность потолка.",

        FAQ_HEADER_2: "Из какой пленки изготавливаются полотна?",
        FAQ_TEXT_2: "Мы используем ПВХ-пленку.",

        FAQ_HEADER_3: "Даете ли Вы гарантию?",
        FAQ_TEXT_3: "Да, мы используем материалы европейского качества и даем гарантию на наши натяжные потолки - 15 лет.",

        FAQ_HEADER_4: "Возможно заказать замер онлайн?",
        FAQ_TEXT_4: "Да, оставляйте номер телефона на сайте и наш менеджер свяжется с Вами для консультации и назначения даты замера, или звоните по телефону указанному на сайте.",

        FAQ_HEADER_5: "Остается неприятный запах после монтажа",
        FAQ_TEXT_5: `Мы работаем по технологии "горячего монтажа" поэтому сразу после установки в комнате остается слабый запах газа, который мы используем. Чтобы избавиться от него, достаточно проветрить комнату в течение часа.`,

        FAQ_HEADER_6: "С какими производителями пленки Вы работаете?",
        FAQ_TEXT_6: "Мы являемся официальными представителями всемирно известных производителей PANIFEL (Франция), KOREA PLAST (Корея), ECOPLASTIKS (Бельгия) и TEQTUM (Германия) в Украине.",

        FAQ_HEADER_7: "Как быстро Вы устанавливаете натяжной потолок?",
        FAQ_TEXT_7: "Продолжительность установки натяжного потолка зависит от размера и сложности помещения. Обычно установка занимает от нескольких часов до нескольких дней. Процесс включает подготовительные работы, растягивание потолка и установку профилей.",

        FAQ_HEADER_8: "Не выделяет ли натяжной потолок вредные химические вещества?",
        FAQ_TEXT_8: "Оригинальные европейские натяжные потолки не выделяют вредных химических веществ, поскольку практически не содержат фенола.",

        FAQ_MORE_QUESTIONS: `Больше вопросов`,
        CONTACTS_TITLE: `Контакты`,
        CONTACTS_TEXT: `Есть вопросы на которые не нашли ответы? `,
        CONTACTS_TEXT_ORANGE: `Позвони нам!`,
        CONTACTS_ADRESS: `Серебрянокольская, 3В`,
        CONTACTS_BUTTON: `Получить скидку до 30%`,
    }
}

export default langDictionary