import React, { useContext, useRef } from "react";
import SectionTitle from "../SectionTitle/SectionTitle.tsx";
import ExamplesSlider, { LeftRightHandle } from "./ExamplesSlider.tsx";
import ImageButton from "../ImageButtons/ImageButton.tsx";
import { Waypoint } from "react-waypoint";

import './OurExamples.css'
import { useLinkStore } from "../../../storage/activeLink.ts";
import LangContext from "../Contexts/LangContext.tsx";
import langDictionary from "../../../constants/uaLocal.tsx";

const OurExamples = () => {
    const lang = useContext(LangContext)

    const examplesRef = useRef<LeftRightHandle>(null)

    const handelLeftClick = () => {
        if (examplesRef.current)
            examplesRef.current.clickLeft()
    }
    const handleRightClick = () => {
        if (examplesRef.current)
            examplesRef.current.clickRight()
    }


    const store = useLinkStore()
    return (
        <>
            <Waypoint bottomOffset={store.bottomOffset} topOffset={store.topOffset} onPositionChange={(args) => store.onPositionChange(args, 'examples')} fireOnRapidScroll>
                <div style={{ width: '100%', height: 'max-content' }}>
                    <SectionTitle title={langDictionary[lang].OUR_EXAMPLES_TITLE} id='our-examples' isRight />
                    <div className="examples-holder">
                        <p className="text-white examples-text">{langDictionary[lang].OUR_EXAMPLES_TEXT}</p>
                        <ImageButton onClick={handelLeftClick} small type="left" background="opaque" />
                        <ImageButton onClick={handleRightClick} small type="right" background="opaque" />
                    </div>
                    <ExamplesSlider ref={examplesRef} />
                </div>
            </Waypoint>
        </>
    )
}

export default OurExamples;