import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";

import SectionTitle from '../SectionTitle/SectionTitle.tsx'
import ImageButton from "../ImageButtons/ImageButton.tsx";
import langDictionary from "../../../constants/uaLocal.tsx";
import { useRepeat } from "../ReapeatFunction.tsx";

import './Feedback.css'
import phoneFrame from '../../../images/Feedback/PhoneImage.png'
import feedbackOne from '../../../images/Feedback/Feedback1.png'
import feedbackTwo from '../../../images/Feedback/Feedback2.png'
import feedbackThree from '../../../images/Feedback/Feedback3.png'
import { Spinner } from "react-bootstrap";
import { Waypoint } from "react-waypoint";
import { useLinkStore } from "../../../storage/activeLink.ts";
import LangContext from "../Contexts/LangContext.tsx";
import PhoneInput, { ErrorHandle } from "../PhoneInput.tsx";
import useSubmitWrapper from "../useSubmitWrapper.ts";
import { OrderCall } from "../../../api/index.ts";


const imgaes = [feedbackOne, feedbackTwo, feedbackThree]

type PhoneHandle = {
    onLeft: () => void,
    onRight: () => void,
}
type PhoneProps = {
    initialNum: number,
    position: 1 | 2 | 3
}

const Phone = forwardRef<PhoneHandle, PhoneProps>(({ initialNum, position }, ref) => {
    const images = imgaes

    const [pos, setPos] = useState<number>(0)
    const [currPos, setCurrPos] = useState<number>(1)
    const [isAnimate, setAnimate] = useState<boolean>(false)

    const imagesRefs = [useRef<HTMLImageElement>(null), useRef<HTMLImageElement>(null), useRef<HTMLImageElement>(null)]
    useImperativeHandle(ref, () => {
        return {
            onLeft: () => onClick(false),
            onRight: () => onClick(true)
        }
    })

    const onClick = (forward?: boolean) => {
        if (isAnimate)
            return;
        setAnimate(true)
        let currImg = imagesRefs.at(currPos)?.current
        let nextImg = imagesRefs.at((currPos + 1) % imagesRefs.length)?.current
        let prevImg = imagesRefs.at(currPos - 1)?.current
        if (currImg) {
            currImg.classList.remove('curr')
            currImg.classList.add(forward ? 'prev' : 'next')
        }
        if (nextImg) {
            nextImg.classList.remove('next')
            nextImg.classList.add(forward ? 'curr' : 'prev')
            nextImg.src = forward ? images.at((pos + 1) % images.length) : ''
            if (!forward) nextImg.classList.add('d-none')
        }
        if (prevImg) {
            prevImg.src = forward ? '' : images.at(pos - 1);
            if (forward) prevImg.classList.add('d-none')
            prevImg.classList.remove('prev')
            prevImg.classList.add(forward ? 'next' : 'curr')
        }

        setTimeout(() => {
            prevImg?.classList.remove('d-none')
            nextImg?.classList.remove('d-none')
            setAnimate(false)
        }, 800)

        setCurrPos(state => forward ? ((state + 1) % 3) : state === 0 ? 2 : (state - 1))
        setPos(state => {
            return forward ? ((state + 1) % images.length) : state === 0 ? (images.length - 1) : (state - 1);
        })
    }

    useEffect(() => {
        if (imagesRefs[1].current) {
            imagesRefs[1].current.src = images.at(initialNum)
            setPos(initialNum)
            imagesRefs[1].current.classList.add('curr')
        }
    }, [])

    return (
        <>
            <div className={"phone phone-" + position}>
                <div className="image-holder">
                    <img className="image prev" alt='comment' ref={imagesRefs[0]} />
                    <img className="image curr" alt='comment' ref={imagesRefs[1]} />
                    <img className="image next" alt='comment' ref={imagesRefs[2]} />
                </div>
                <img className="frame" src={phoneFrame} alt='frame' />
            </div>
        </>
    )
})

const Feedback = () => {
    const phonesRef = [useRef<PhoneHandle>(null), useRef<PhoneHandle>(null), useRef<PhoneHandle>(null)]
    const [phoneNumber, setPhoneNumber] = useState<string>("")
    const [isRepeating, setRepeating] = useState<boolean>(true)

    const { clearResult, result, setDataAndFetch, submitting } = useSubmitWrapper()

    const handlers = useSwipeable({
        onSwipedLeft: nextClick,
        onSwipedRight: prevClick,
        swipeDuration: 2000,
    })

    function nextClick() {
        phonesRef.forEach(ref =>
            ref.current?.onRight()
        )
        restart()
    }

    function prevClick() {
        phonesRef.forEach(ref =>
            ref.current?.onLeft()
        )
        restart()
    }

    useEffect(() => {
        if (!isRepeating)
            setRepeating(true)
    }, [isRepeating])


    useEffect(() => {
        console.log(result)
    }, [result])

    const store = useLinkStore()

    const { restart } = useRepeat(() => phonesRef.forEach(ref =>
        ref.current?.onRight()
    ), 5000)

    const phoneInputRef = useRef<ErrorHandle>()

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        if (/^\+380[5679]\d{8}$/.test(phoneNumber)) {
            let data: OrderCall = {
                form: "feedback",
                phoneNumber: phoneNumber,
            }
            setDataAndFetch(data)
        }
        else {
            phoneInputRef.current?.setShowPhoneError(true)
        }
    }

    const lang = useContext(LangContext)
    return (
        <>
            <Waypoint bottomOffset={store.bottomOffset} topOffset={store.topOffset} onPositionChange={(args) => store.onPositionChange(args, "feedback")} fireOnRapidScroll>
                <div>
                    <SectionTitle title={langDictionary[lang].FEEDBACK_TITLE} id="feedback" />
                    <div className="feedback">
                        <div className="buttons-holder" {...handlers}>
                            <ImageButton type="left" onClick={prevClick} background="dark" />
                            <ImageButton type="right" onClick={nextClick} background="dark" />
                        </div>
                        <Phone initialNum={0} position={1} ref={phonesRef[0]} />
                        <Phone initialNum={1} position={2} ref={phonesRef[1]} />
                        <Phone initialNum={2} position={3} ref={phonesRef[2]} />
                    </div>
                    <form className="feedback-call" onSubmit={onSubmit}>
                        <div className="calc-input phone">
                            <PhoneInput phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} ref={phoneInputRef} />
                            <button type="submit" formMethod="post">
                                {
                                    submitting ?
                                        <Spinner variant="light" />
                                        :
                                        langDictionary[lang].FREE_MEASUREMENT
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </Waypoint>
        </>
    )
}

export default Feedback;