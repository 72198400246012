import React, { useContext, useEffect, useRef, useState } from "react";
import { Dropdown, Modal, Spinner } from "react-bootstrap";

import glossyExample from '../../../images/Calc/glossy.png'
import matExample from '../../../images/Calc/mat.png'
import satinExample from '../../../images/Calc/satin.png'

import './Calculator.css'
import './CalculatorColorPicker.css'
import './CalculatorTabs.css'
import './CalculatorSelect.css'
import './CalculatorSocials.css'

import SectionTitle from '../SectionTitle/SectionTitle.tsx'
import langDictionary from "../../../constants/uaLocal.tsx";
import CustomToggle from "./DropdownCustomToggle.jsx";
import { OrderCall } from "../../../api/index.ts";
import LangContext from "../Contexts/LangContext.tsx";
import PhoneInput, { ErrorHandle } from "../PhoneInput.tsx";
import useSubmitWrapper from "../useSubmitWrapper.ts";

const colors = ['#DEDACF', '#D1C3A9', '#D3C096', '#BD9C73', '#90B5B5', '#B4B7BE', '#5D92BA', '#9F94B2', '#917198',
    '#CDC9BD', '#D5CAAE', '#A09D8C', '#8F8F8D', '#4D5759', '#757774', '#BBA58E', '#D4919A', '#000000']

const Calculator = () => {
    const [selectedType, setSelectedType] = useState<'mat' | 'satin' | 'glossy'>("mat")
    const [selectedColor, setSelectedColor] = useState<typeof colors[number]>('#DEDACF')
    const [area, setArea] = useState<string>("0")
    const [phoneNumber, setPhoneNumber] = useState<string>("")
    const [selectedMessanger, setSelectedMessanger] = useState<'Telegram' | 'Viber' | 'WhatsApp' | 'Signal'>()

    const [showResultModal, setResultModal] = useState<boolean>(false)

    const selectMessanger = (e) => {
        let val = e?.currentTarget?.value
        setSelectedMessanger(state => {
            return val === state ? undefined : val
        })
    }

    const clickRadio = (e) => {
        if (e?.target?.value === selectedMessanger) {
            setSelectedMessanger(undefined)
        }
    }

    const lang = useContext(LangContext)

    const typeDict = {
        mat: langDictionary[lang].MAT,
        satin: langDictionary[lang].SATIN,
        glossy: langDictionary[lang].GLOSSY
    }

    const selectType = (e) => {
        setSelectedType(e.currentTarget.value)
    }

    const onAreaChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        let newValue = e.target.value
        if (!/[^\d,.]|[.,].*[.,]/.test(newValue)) {
            setArea(newValue)
        }
    }

    const { setDataAndFetch, submitting, result, clearResult } = useSubmitWrapper()

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        phoneInputRef.current?.setShowPhoneError(!/^\+380[5679]\d{8}$/.test(phoneNumber));
        if (/^\+380[5679]\d{8}$/.test(phoneNumber)) {
            const jsonData: OrderCall = {
                phoneNumber: phoneNumber,
                comment: `Меседжер: ${selectedMessanger},\nПлоща: ${area},\nТип стелі: ${typeDict[selectedType]}`,
                color: selectedColor,
                form: "calc"
            }
            setDataAndFetch(jsonData)
        }
    }

    useEffect(() => {
        console.log(result)
    },[result])

    const phoneInputRef = useRef<ErrorHandle>(null)
    return (
        <>
            <SectionTitle title={langDictionary[lang].CALCULATOR} isRight portraitOnly />
            <div className="calc-spacer" />
            <div className="calc">
                <img className="calc-placeholder" src={selectedType === 'glossy' ? glossyExample : selectedType === 'mat' ? matExample : satinExample} alt='placeholder' style={{ backgroundColor: selectedColor }} />
                <form onSubmit={onSubmit}>
                    <p className="text-white heading">{langDictionary[lang].CALCULATOR_INFO}</p>
                    <div className="calc-input">
                        <p className="text-white small">{langDictionary[lang].CALC_FACTURE}</p>
                        <Dropdown className="text-white" style={{ fontSize: '3vh' }} data-bs-theme="dark" id="facture">
                            <Dropdown.Toggle as={CustomToggle}>{typeDict[selectedType]}</Dropdown.Toggle>
                            <Dropdown.Menu className="ddown bg-custom">
                                <Dropdown.Item className="custom-item" active={selectedType === 'mat'} onClick={() => setSelectedType("mat")}>{langDictionary[lang].MAT}</Dropdown.Item>
                                <Dropdown.Item className="custom-item" active={selectedType === 'glossy'} onClick={() => setSelectedType("glossy")}>{langDictionary[lang].GLOSSY}</Dropdown.Item>
                                <Dropdown.Item className="custom-item" active={selectedType === 'satin'} onClick={() => setSelectedType("satin")}>{langDictionary[lang].SATIN}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="facture-radio-holder">
                        <label className="calc-container text-white">
                            <input type="radio" className="facture-radio" onChange={selectType} checked={selectedType === 'mat'} value="mat" id='mat' name='rooftype' />
                            <p>{langDictionary[lang].MAT}</p>
                        </label>
                        <label className="calc-container text-white">
                            <input type="radio" className="facture-radio" onChange={selectType} checked={selectedType === 'glossy'} value="glossy" id='glossy' name='rooftype' />
                            <p>{langDictionary[lang].GLOSSY}</p>
                        </label>
                        <label className="calc-container text-white">
                            <input type="radio" className="facture-radio" onChange={selectType} checked={selectedType === 'satin'} value="satin" id='satin' name='rooftype' />
                            <p>{langDictionary[lang].SATIN}</p>
                        </label>
                    </div>
                    <div className="calc-input">
                        <p className="text-white small">{langDictionary[lang].CALC_COLOR}</p>
                        <div style={{ display: 'flex', gap: '1vh', flexWrap: 'wrap', justifyContent: 'center' }}>
                            {colors.map((color, id) =>
                                <label className="color-container" key={id}>
                                    <input
                                        className="checkbox"
                                        type="radio"
                                        value={color}
                                        key={id}
                                        id={color}
                                        name='color-picker'
                                        checked={color === selectedColor}
                                        onChange={(e) => setSelectedColor(e.currentTarget.value)} />
                                    <div style={{ borderRadius: '100%', width: 'max(5vmin,28px)', height: 'max(5vmin,28px)', backgroundColor: color }} >
                                        <i className="bi bi-check2 checkmark text-center" />
                                    </div>
                                </label>
                            )}
                        </div>
                    </div>
                    <div className="calc-input">
                        <p className="text-white small">{langDictionary[lang].CALC_AREA}</p>
                        <p className="text-white large">{langDictionary[lang].CALC_AREA_LONG}</p>
                        <div className="area-input-holder">
                            <input className="area-input" type="text" name="area" placeholder="кв.м." onChange={onAreaChange} value={area} />
                            <div className="area-input-ending">кв.м.</div>
                        </div>
                    </div>
                    <div className="calc-input">
                        <p className="text-white small">{langDictionary[lang].CALC_MESSANGER}</p>
                        <p className="text-white large">{langDictionary[lang].CALC_MESSANGER_LONG}</p>
                        <div className="messanger-radio-holder">
                            <label className="social-container">
                                <input type="radio" value={"Telegram"} id='TG' name='messanger' onChange={selectMessanger} onClick={clickRadio} checked={selectedMessanger === "Telegram"} />
                                <div className="social telegram" />
                            </label>
                            <label className="social-container">
                                <input type="radio" value={"Viber"} id='Viber' name='messanger' onChange={selectMessanger} onClick={clickRadio} checked={selectedMessanger === "Viber"} />
                                <div className="social viber" />
                            </label>
                            <label className="social-container">
                                <input type="radio" value={"Signal"} id='Signal' name='messanger' onChange={selectMessanger} onClick={clickRadio} checked={selectedMessanger === "Signal"} />
                                <div className="social signal" />
                            </label>
                            <label className="social-container">
                                <input type="radio" value={"WhatsApp"} id='WA' name='messanger' onChange={selectMessanger} onClick={clickRadio} checked={selectedMessanger === "WhatsApp"} />
                                <div className="social whatsapp" />
                            </label>
                        </div>
                    </div>
                    <div className="calc-input phone">
                        <PhoneInput phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} ref={phoneInputRef} />
                        <button type="submit" formMethod="post">
                            {
                                submitting ?
                                    <Spinner variant="light" />
                                    :
                                    langDictionary[lang].CALC_GET_CALL
                            }
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Calculator

