import React from "react";
import useNavItems from "../useNavItems.tsx";
import phoneNumbers from "../../../constants/phoneNumbers.ts";
import LangSelector from "../LangSelector/LangSelector.tsx";

import './Footer.css'

export default function Footer() {
    const items = useNavItems()
    return (
        <>
            <div className="footer">
                {items.map(item =>
                    <a href={item[1]} key={item[0]}>
                        <p>{item[0]}</p>
                    </a>
                )}
                <div className="phone-numbers">
                    {phoneNumbers.map(pn =>
                        <a href={`tel:${pn}`} key={pn}>
                            <p>{pn}</p>
                        </a>
                    )}
                </div>
                <LangSelector/>
            </div>
        </>
    )
}