import React, { useContext } from "react";
import SectionTitle from "../SectionTitle/SectionTitle.tsx";
import langDictionary from "../../../constants/uaLocal.tsx";

import './Steps.css'
import Squares from "./Squares.tsx";
import { CustomAccordion, CustomAccordionItem } from "../CustomAccordion/CustomAccordion.tsx";
import LangContext from "../Contexts/LangContext.tsx";


const Steps = () => {
    const lang = useContext(LangContext)
    return (
        <>
            <SectionTitle title={langDictionary[lang].STEPS_STITLE} />
            <h1 className="steps-title">{langDictionary[lang].STEPS_SUBTITLE_NORMAL}<span className="orange">{langDictionary[lang].STEPS_SUBTITLE_ORANGE}</span></h1>
            <CustomAccordion>
                <CustomAccordionItem eventKey="1" number={1} header={langDictionary[lang].STEPS_FIRST_TITLE}>
                    {langDictionary[lang].STEPS_FIRST_TEXT}
                </CustomAccordionItem>
                <CustomAccordionItem eventKey="2" number={2} header={langDictionary[lang].STEPS_SECOND_TITLE}>
                    {langDictionary[lang].STEPS_SECOND_TEXT}
                </CustomAccordionItem>
                <CustomAccordionItem eventKey="3" number={3} header={langDictionary[lang].STEPS_THIRD_TITLE}>
                    {langDictionary[lang].STEPS_THIRD_TEXT}
                </CustomAccordionItem>
                <CustomAccordionItem eventKey="4" number={4} header={langDictionary[lang].STEPS_FOURTH_TITLE}>
                    {langDictionary[lang].STEPS_FOURTH_TEXT}
                </CustomAccordionItem>
            </CustomAccordion>
            <h1 className="after-steps"><span className="orange">{langDictionary[lang].AFTERSTEPS_STELIOK_NAME}</span> {langDictionary[lang].AFTERSTEPS_STELIOK_TEXT}</h1>
            <Squares />
        </>
    )
}

export default Steps;