import React, { useContext } from "react";

import SectionTitle from '../SectionTitle/SectionTitle.tsx'
import langDictionary from "../../../constants/uaLocal.tsx";

import './Specials.css'
import LangContext from "../Contexts/LangContext.tsx";
import { useLinkStore } from "../../../storage/activeLink.ts";
import { Waypoint } from "react-waypoint";

import partners from '../../../images/partners.png'

const Specials = () => {
    const lang = useContext(LangContext)
    const store = useLinkStore()


    return (
        <>
            <SectionTitle title={langDictionary[lang].SPECIALS_TITLE} id="partners" />
            <Waypoint bottomOffset={store.bottomOffset} topOffset={store.topOffset} onPositionChange={(args) => store.onPositionChange(args, "partners")} fireOnRapidScroll>
                <div className="spec">
                    <div style={{display: 'flex', alignItems: 'center', width: '32%', left:'5%', height: '100%'}}>
                        <img className="spec-img" alt="specials_image" src={partners} />
                    </div>

                    <div className="lines-holder">
                        <svg width="100%" height="100%" style={{ left: 0 }}>
                            <line strokeWidth={2} x1="20%" y1="22%" x2="40%" y2="78%" stroke="currentColor" />
                            <line strokeWidth={2} x1="40%" y1="78%" x2="60%" y2="22%" stroke="currentColor" />
                            <line strokeWidth={2} x1="60%" y1="22%" x2="80%" y2="78%" stroke="currentColor" />
                        </svg>
                        <div className="circle first" >
                            <div />
                        </div>
                        <div className="text first">
                            <p>{langDictionary[lang].SPECIALS_FIRST}</p>
                        </div>
                        <div className="circle second">
                            <div />
                        </div>
                        <div className="text second">
                            <p>{langDictionary[lang].SPECIALS_SECOND}</p>
                        </div>
                        <div className="circle third">
                            <div />
                        </div>
                        <div className="text third">
                            <p>{langDictionary[lang].SPECIALS_THIRD}</p>
                        </div>
                        <div className="circle fourth">
                            <div />
                        </div>
                        <div className="text fourth">
                            <p>{langDictionary[lang].SPECIALS_FOURTH}</p>
                        </div>
                    </div>
                </div>
            </Waypoint>
        </>
    )
}

export default Specials