import React, { FC, useContext, useEffect, useRef, useState } from "react";
import SectionTitle from '../SectionTitle/SectionTitle.tsx'
import langDictionary from "../../../constants/uaLocal.tsx";
import ImageButton from "../ImageButtons/ImageButton.tsx";

import './Materials.css'
import './MaterialModalAnimations.css'
import { useSwipeable } from "react-swipeable";

import glossy from '../../../images/materials/glossy.png'
import satin from '../../../images/materials/satin.png'
import mat from '../../../images/examples/example08.png'
import FunctionsContext from "../Contexts/FunctionsContext.tsx";
import { Waypoint } from "react-waypoint";
import { useLinkStore } from "../../../storage/activeLink.ts";
import LangContext from "../Contexts/LangContext.tsx";
import { CSSTransition } from "react-transition-group";


type MaterialCardProps = {
    className: string,
    id: number,
    src: string
}
const MaterialCard: FC<MaterialCardProps> = ({ className, id, src }) => {
    let type: 'glossy' | 'satin' | 'mat' = src === glossy ? 'glossy' : src === satin ? 'satin' : 'mat'
    const lang = useContext(LangContext)

    useEffect(() => {
        switch (type) {
            case "glossy":
                setText(langDictionary[lang].GLOSSY_MATERIAL);
                setTitle(langDictionary[lang].GLOSSY)
                break;
            case "satin":
                setText(langDictionary[lang].SATIN_MATERIAL);
                setTitle(langDictionary[lang].SATIN);
                break;
            case "mat":
                setText(langDictionary[lang].MAT_MATERIAL);
                setTitle(langDictionary[lang].MAT);
                break;
        }
    }, [lang, type])
    const [show, setShow] = useState<boolean>(false)
    const funcs = useContext(FunctionsContext)
    const [text, setText] = useState<string>('')
    const [title, setTitle] = useState<string>('')


    const onMoreClick = () => {
        funcs?.disableScroll()
        setShow(true)
    }
    const onHideClick = () => {
        funcs?.enableScroll()
        setShow(false)
    }

    const transitionRef = useRef<HTMLDivElement>(null)

    return (
        <>
            <CSSTransition in={show} nodeRef={transitionRef} timeout={500} classNames='modal-anim' mountOnEnter unmountOnExit>
                <div ref={transitionRef}>
                    <div className={"material-modal-backdrop"} onClick={onHideClick}>
                    </div>
                    <div className={"material-modal"}>
                        <i className="bi bi-x-circle-fill" onClick={onHideClick} />
                        <p className="text-white">{text}</p>
                    </div>
                </div>
            </CSSTransition>
            <div className={className + ''} key={id}>
                <img src={src} alt={'material' + id} className="material-img" />
                <div className="material-title">
                    <h1 className="text-white">{title}</h1>
                    <button className="material-more text-white" type="button" onClick={onMoreClick}>{langDictionary[lang].MORE}{' '}<i className="bi bi-box-arrow-up-right" /></button>
                </div>
                <p className="text-white">{text}</p>
            </div>
        </>
    )
}

const Materials = () => {
    const images = [glossy, satin, mat]
    const [animatedDirection, setAnimatedDirections] = useState<'left' | 'right' | 'none'>('none')
    const [index, setIndex] = useState<number>(0)

    const nextClick = () => {
        if (animatedDirection === 'none') {
            setIndex(state => { if (state >= 2) return 0; return ++state; })
            setAnimatedDirections('right')
            setTimeout(() => { setAnimatedDirections('none') }, 500)
        }
    }
    const prevClick = () => {
        if (animatedDirection === 'none') {
            setIndex(state => { if (state <= 0) return 2; return --state; })
            setAnimatedDirections('left')
            setTimeout(() => { setAnimatedDirections('none') }, 500)
        }
    }
    const handlers = useSwipeable({
        onSwipedLeft: nextClick,
        onSwipedRight: prevClick,
        swipeDuration: 2000,
    })


    const store = useLinkStore()

    const lang = useContext(LangContext)
    return (
        <>
            <Waypoint bottomOffset={store.bottomOffset} topOffset={store.topOffset} onPositionChange={(args) => store.onPositionChange(args, 'materials')} fireOnRapidScroll>
                <div>
                    <SectionTitle title={langDictionary[lang].MATERIALS_TITLE} id="materials" />
                    <div className="position-relative">
                        <div {...handlers} className="material-buttons-holder">
                            <ImageButton type="left" onClick={prevClick} small />
                            <ImageButton type="right" onClick={nextClick} small />
                        </div>
                        <div className="material-images-holder" >
                            <hr />
                            <hr />
                            <div className="dot dot-1" />
                            <div className="dot dot-2" />
                            <div className="dot dot-3" />
                            <div className={`dot dot-active dot-${index + 1}`} />
                            {images.map((image, id) => {
                                let ids = [0, 1, 2]
                                let classNames = "material"
                                let diff = ids.at(index - id)
                                switch (diff) {
                                    case 0:
                                        classNames += ' material-curr'
                                        break;
                                    case 1:
                                        classNames += ' material-prev' + (animatedDirection === 'left' ? ' back-move' : '')
                                        break;
                                    case 2:
                                        classNames += ' material-next' + (animatedDirection === 'right' ? ' back-move' : '')
                                        break;
                                    default: break;
                                }
                                return <MaterialCard key={id} className={classNames} id={id} src={image} />
                            })}
                        </div>
                    </div>
                </div>
            </Waypoint>
        </>
    )
}

export default Materials