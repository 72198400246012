import React, { useContext, useEffect, useRef, useState } from "react";
import { Form } from 'react-router-dom'

import './Contacts.css'
import GoogleMaps from './GoogleMaps.tsx'
import PhoneInput, { ErrorHandle } from "../PhoneInput.tsx";
import phoneNumbers from "../../../constants/phoneNumbers.ts";
import LangContext from "../Contexts/LangContext.tsx";
import langDictionary from "../../../constants/uaLocal.tsx";
import { Waypoint } from "react-waypoint";
import { useLinkStore } from "../../../storage/activeLink.ts";
import { OrderCall } from "../../../api/index.ts";
import useSubmitWrapper from "../useSubmitWrapper.ts";
import { Spinner } from "react-bootstrap";

const Contacts = () => {
    const [phoneNumber, setPhoneNumber] = useState<string>("")

    const lang = useContext(LangContext)

    const phoneInputRef = useRef<ErrorHandle>(null)
    const { clearResult, result, setDataAndFetch, submitting } = useSubmitWrapper()

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        if (/^\+380[5679]\d{8}$/.test(phoneNumber)) {
            let data: OrderCall = {
                form: "contacts",
                phoneNumber: phoneNumber
            }
            setDataAndFetch(data)
        }
    }

    useEffect(() => {
        console.log(result)
    }, [result])
    const store = useLinkStore()
    return (
        <>
            <Waypoint topOffset={store.topOffset} onPositionChange={(args) => store.onPositionChange(args, "contacts")} fireOnRapidScroll>
                <div id="contacts" className="contacts">
                    <hr />
                    <h3>{langDictionary[lang].CONTACTS_TEXT}<span className="orange">{langDictionary[lang].CONTACTS_TEXT_ORANGE}</span></h3>
                    <div className="holder">
                        <Form className="form" onSubmit={onSubmit}>
                            <PhoneInput phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} className="phone-num" ref={phoneInputRef} />
                            <button type="submit">
                                {
                                    submitting
                                        ?
                                        <Spinner variant="light" />
                                        :
                                        langDictionary[lang].CONTACTS_BUTTON
                                }
                            </button>
                            <div className="social-group">
                                <div><a target="_blank" href="https://web.telegram.org/" className="telegram"> </a></div>
                                <div><a target="_blank" href="https://www.viber.com/" className="viber"> </a></div>
                                <div><a target="_blank" href="https://web.whatsapp.com/" className="whatsapp"> </a></div>
                                <div><a target="_blank" href="https://www.facebook.com/" className="facebook"> </a></div>
                            </div>
                        </Form>
                        <div className="phones">
                            <h1 className="heading-text title">{langDictionary[lang].CONTACTS_TITLE}</h1>
                            <div className="phones-holder">
                                <div>
                                    <div className="line-hr line1" />
                                    <div className="line-vr line2" />
                                    <div className="line-hr line3" />
                                    <div className="line-vr line4" />
                                    <div className="socials">
                                        <div className="small-social telegram" />
                                        <div className="small-social whatsapp" />
                                        <div className="small-social facebook" />
                                    </div>
                                </div>
                                {phoneNumbers.map(pn => <p key={pn}>{pn}</p>)}
                                <p>{langDictionary[lang].CONTACTS_ADRESS}</p>
                            </div>
                        </div>
                        <div className="map-holder"><GoogleMaps /></div>
                    </div>
                    <hr />
                </div>
            </Waypoint>
            {/* navs */}
        </>
    )
}

export default Contacts