import React, { useContext, useEffect, useState } from "react";

import './Main.css'
import { useSliderStore } from "../../../storage/slider.ts";
import Slider from "./Slider.tsx";
import ImageButton from "../ImageButtons/ImageButton.tsx";
import { useRepeat } from "../ReapeatFunction.tsx";
import langDictionary from "../../../constants/uaLocal.tsx";
import LangContext from "../Contexts/LangContext.tsx";

const Main = () => {

    const { increment, decrement } = useSliderStore()

    const { restart } = useRepeat(increment, 10000)

    const [isAnimation, setIsAnimation] = useState<boolean>(false)
    useEffect(() => {
        if (isAnimation === true)
            setTimeout(() => setIsAnimation(false), 600)

    }, [isAnimation])

    const nextSlide = () => {
        setIsAnimation(true)
        if (!isAnimation) {
            increment()
            restart()
        }
    }

    const prevSlide = () => {
        setIsAnimation(true)
        if (!isAnimation) {
            restart()
            decrement()
        }
    }

    const lang = useContext(LangContext)

    return (
        <div className="main">
            <Slider />
            <div className="info">
                <div>
                    <h1 className="text-white text-center title">
                        <span className="top">{langDictionary[lang].MAIN_TOP}</span>
                        <br />
                        <span className="dash">—</span>{' '}
                        <span className="bot">{langDictionary[lang].MAIN_BOTTOM}</span>
                        {' '}<span className="dash">—</span>
                    </h1>
                    <h2 className="text-white text-center sub-title text-wrap">
                        {langDictionary[lang].MAIN_SUB_TOP}
                        <br />
                        {langDictionary[lang].MAIN_SUB_BOTTOM}
                    </h2>
                    <div className="buttons-holder">
                        <ImageButton disabled={isAnimation} onClick={prevSlide} type='left' />
                        <ImageButton disabled={isAnimation} onClick={nextSlide} type='right' />
                    </div>
                </div>
            </div>
            <div className="first-page" />
        </div>
    )
}

export default Main;