import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import langDictionary from "../../constants/uaLocal.tsx";
import LangContext from "./Contexts/LangContext.tsx";
import useClickOutside from "./useClickOutside.tsx";

type PhoneInputProps = { phoneNumber: string, setPhoneNumber: React.Dispatch<React.SetStateAction<string>>, className?: string };
export type ErrorHandle ={
    setShowPhoneError: React.Dispatch<React.SetStateAction<boolean>>
}

const PhoneInput = forwardRef<ErrorHandle, PhoneInputProps>(({ phoneNumber, setPhoneNumber, className }, ref) => {
    const popupStyle: React.CSSProperties = {
        position: 'absolute',
        backgroundColor: 'rgba(255, 100, 100, 0.85)',
        padding: '2px 10px',
        color: 'white',
        borderRadius: 3,
        fontSize: '2vh',
        maxWidth: '50vw',
        textAlign: 'center'
    }

    const phoneNumberRef = useRef<HTMLInputElement>(null)
    const overlayRef = useRef(null)
    const [showPhoneError, setShowPhoneError] = useState<boolean>(false)


    const onPhoneNumberChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        let newValue = e.target.value;
        if (/^\+?\d*$/.test(newValue)) {
            setPhoneNumber(newValue)
        }
    }
    const onPhoneNumberBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
        let newValue = e.target.value
        if (/^(\+380|380|0|)[5679]\d{8}$/.test(newValue)) {
            setPhoneNumber('+380'.substring(0, 13 - (newValue.length)).concat(newValue))
        }
        else {
            setShowPhoneError(true)
        }
    }

    const lang = useContext(LangContext)
    useClickOutside(overlayRef, () => setShowPhoneError(false))

    useImperativeHandle(ref, () =>{
        return {
            setShowPhoneError(value: React.SetStateAction<boolean>){
                setShowPhoneError(value);
            }
        }
    })

    return (
        <>
            <input
                type="tel"
                ref={phoneNumberRef}
                placeholder={langDictionary[lang].YOUR_PHONE}
                onChange={onPhoneNumberChange}
                onBlur={onPhoneNumberBlur}
                onFocus={() => setShowPhoneError(false)}
                className={className}
                value={phoneNumber} />
            <Overlay target={phoneNumberRef.current} show={showPhoneError} placement="top" ref={overlayRef}>
                {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                }) => (
                    <div
                        {...props}
                        style={{ ...popupStyle, ...props.style }}
                    >
                        {langDictionary[lang].WRONG_NUMBER}
                    </div>
                )}
            </Overlay>
        </>
    )
})

export default PhoneInput