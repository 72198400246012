import React, { FC, useContext } from "react";
import './Squares.css'
import langDictionary from "../../../constants/uaLocal.tsx";
import LangContext from "../Contexts/LangContext.tsx";

type SquareProps = {
    number?: number,
    text?: string,
    isImage?: boolean,
    count?: number
}

const Square: FC<SquareProps> = ({ number, text, isImage = false, count }) => {
    const additionalClass = number ? ' sq-' + number : ''
    return (
        <>
            <div className={"outer-sq" + additionalClass + (isImage ? ' image' : '')}>
                <div className='inner-sq'>
                    {!isImage && <>
                        <p>{count}</p>
                        <p>{text}</p>
                    </>}
                </div>
            </div>
        </>
    )
}
const Squares = () => {
    const lang = useContext(LangContext)
    return (
        <>
            <div className="squares-holder">
                <Square number={1} text={langDictionary[lang].AFTERSTEPS_YEARS} count={14}></Square>
                <Square number={2} isImage></Square>
                <Square number={3} text={langDictionary[lang].AFTERSTEPS_CLIENTS} count={4000}></Square>
                <Square number={4} text={langDictionary[lang].AFTERSTEPS_DEALERS} count={150}></Square>
                <Square number={5} text={langDictionary[lang].AFTERSTEPS_FINISHED} count={800}></Square>
            </div>
        </>
    )
}

export default Squares;