import React, { useContext, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import SectionTitle from "../SectionTitle/SectionTitle.tsx";
import langDictionary from "../../../constants/uaLocal.tsx";
import { CustomAccordion, CustomAccordionItem } from "../CustomAccordion/CustomAccordion.tsx";

import './Faq.css'
import FaqImg from '../../../images/FAQ.png'
import LangContext from "../Contexts/LangContext.tsx";

const Faq = () => {

    const [allQuestions, setAllQuestions] = useState<boolean>(false)
    const nodeRef = useRef<HTMLButtonElement>(null)
    const lang = useContext(LangContext)

    return (
        <>
            <SectionTitle title={langDictionary[lang].FAQ_TITLE} isRight />
            <div className="faq-holder">
                <div className="faq-accordion-button">
                    <CustomAccordion style={{ width: '100%' }}>
                        <CustomAccordionItem number={1} eventKey="1" header={langDictionary[lang].FAQ_HEADER_1}>{langDictionary[lang].FAQ_TEXT_1}</CustomAccordionItem>
                        <CustomAccordionItem number={2} eventKey="2" header={langDictionary[lang].FAQ_HEADER_2}>{langDictionary[lang].FAQ_TEXT_2}</CustomAccordionItem>
                        <CustomAccordionItem number={3} eventKey="3" header={langDictionary[lang].FAQ_HEADER_3}>{langDictionary[lang].FAQ_TEXT_3}</CustomAccordionItem>
                        <CustomAccordionItem number={4} eventKey="4" header={langDictionary[lang].FAQ_HEADER_4}>{langDictionary[lang].FAQ_TEXT_4}</CustomAccordionItem>
                        {
                            allQuestions &&
                            <>
                                <CustomAccordionItem className="faq-anim-in" number={5} eventKey="5" header={langDictionary[lang].FAQ_HEADER_5} style={{ animationDelay: ".0s", transformOrigin: "50% 0" }}>{langDictionary[lang].FAQ_TEXT_5}</CustomAccordionItem>
                                <CustomAccordionItem className="faq-anim-in" number={6} eventKey="6" header={langDictionary[lang].FAQ_HEADER_6} style={{ animationDelay: ".1s", transformOrigin: "50% -15%" }}>{langDictionary[lang].FAQ_TEXT_6}</CustomAccordionItem>
                                <CustomAccordionItem className="faq-anim-in" number={7} eventKey="7" header={langDictionary[lang].FAQ_HEADER_7} style={{ animationDelay: ".2s", transformOrigin: "50% -30%" }}>{langDictionary[lang].FAQ_TEXT_7}</CustomAccordionItem>
                                <CustomAccordionItem className="faq-anim-in" number={8} eventKey="8" header={langDictionary[lang].FAQ_HEADER_8} style={{ animationDelay: ".3s", transformOrigin: "50% -45%" }}>{langDictionary[lang].FAQ_TEXT_8}</CustomAccordionItem>
                            </>
                        }
                    </CustomAccordion>
                    <CSSTransition in={!allQuestions} nodeRef={nodeRef} timeout={500} classNames={'faq-button'} unmountOnExit>
                        <button onClick={() => setAllQuestions(true)} ref={nodeRef}>
                            {langDictionary[lang].FAQ_MORE_QUESTIONS}
                        </button>
                    </CSSTransition>
                </div>
                <img src={FaqImg} alt="image_for_faq" style={{ height: '88vh', position: 'sticky', top: '10vh', objectFit: 'cover' }} />
            </div>
        </>
    )
}

export default Faq;