import React, { Key, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";

import SectionTitle from '../SectionTitle/SectionTitle.tsx'
import { useRepeat } from '../ReapeatFunction.tsx'
import langDictionary from "../../../constants/uaLocal.tsx";

import './Advantages.css'
import './AdvantagesAnimations.css'

import anyComplexityImg from '../../../images/adv/any_complexity.svg'
import certifiedImg from '../../../images/adv/certified.svg'
import ecoMaterialsImg from '../../../images/adv/eco_materials.svg'
import lowestPricesImg from '../../../images/adv/lowest_prices.svg'
import perfectSurfaceImg from '../../../images/adv/perfect_surface.svg'
import waterproofImg from '../../../images/adv/waterproof.svg'
import LangContext from "../Contexts/LangContext.tsx";
import { CSSTransition, SwitchTransition } from "react-transition-group";

type AdvantageSectionProps = {
    text: string,
    smallText: React.ReactNode,
    title: string,
    number: number,
    imgSrc?: string,
    isOpened?: boolean,
    onClick?: React.MouseEventHandler<HTMLDivElement>,
}

type TurnOverHandle = {
    turnOver: () => void,

}
const AdvantageSection = forwardRef<TurnOverHandle, AdvantageSectionProps>(({ text, smallText, title, number, imgSrc, isOpened = false, onClick }, ref) => {

    const faceRef = useRef<HTMLDivElement>(null)
    const backRef = useRef<HTMLDivElement>(null)
    const nodeRef = isOpened ? faceRef : backRef;
    const svgRef = useRef<HTMLElement>(null);

    return (
        <>
            <div id={`triangle-${number}`} className="adv-info triangle" onClick={onClick}>
                <SwitchTransition >
                    <CSSTransition key={isOpened ? 'opened' : 'closed'}
                        nodeRef={svgRef}
                        addEndListener={(done) => svgRef.current?.addEventListener('transitioned', done, false)}
                        timeout={500}
                        classNames='fade'>
                        {/* @ts-ignore */}
                        <svg ref={svgRef} viewBox="0 0 100 86.6025403784" xmlns="http://www.w3.org/2000/svg" style={{ position: 'relative' }} className={(number % 2) === 1 ? '' : 'mirrored'}>
                            <path d="M45.6699 16.1603L11.8301 74.7724C9.90563 78.1058 12.3113 82.2724 16.1603 82.2724H83.8397C87.6888 82.2724 90.0944 78.1058 88.1699 74.7724L54.3301 16.1603C52.4056 12.8269 47.5944 12.8269 45.6699 16.1603Z" />
                        </svg>
                    </CSSTransition>
                </SwitchTransition>
                <div className="adv-single-title">
                    <div className="adv-num-holder">
                        <p>{number}</p>
                    </div>
                    <h1 className="adv-single-title-text">{title}</h1>
                </div>
                <div className="triangle-text-holder" style={{ transform: (number % 2 === 0) ? 'rotate(180deg)' : 'none' }}>

                    <SwitchTransition >
                        <CSSTransition key={isOpened ? 'opened' : 'closed'}
                            nodeRef={nodeRef}
                            addEndListener={(done) => nodeRef.current?.addEventListener('transitioned', done, false)}
                            timeout={500}
                            classNames='fade'>
                            <div className="tri-text" ref={nodeRef}>
                                {
                                    isOpened ?
                                        <div className={`triangle-header show`}>
                                            <img className="triangle-image" src={imgSrc} alt='' style={{ width: '10vw', borderRadius: '50%', objectFit: 'contain' }} />
                                            <h1 className="triangle-title">{title}</h1>
                                        </div>
                                        :
                                        <>
                                            <div style={{
                                                shapeOutside: (number % 2 === 0) ? 'polygon(0 -10%, 0 100%, 110% 100%)' : 'polygon(0 0, 110% 0, 0 110%)',
                                            }} />
                                            <div style={{
                                                shapeOutside: (number % 2 === 0) ? 'polygon(-10% 100%, 100% 100%, 100% -10%)' : 'polygon(-10% 0, 100% 110%, 100% 0)',
                                            }} />
                                            <p className={`adv-small show`} style={{
                                                paddingTop: `${4 * ((number % 2) * 3 + 1)}vw`
                                            }}>{smallText}</p>
                                        </>
                                }
                            </div>

                        </CSSTransition>
                    </SwitchTransition>

                </div>
                <p className="adv-text" style={{ textOverflow: 'ellipsis' }}>{text}</p>
            </div>
        </>
    )
})

const Advantages = () => {
    const lang = useContext(LangContext)

    const [openedNumber, setOpenedNumber] = useState<number>(1)

    const { restart } = useRepeat(() => setOpenedNumber(state => ((state + 1) % 6)), 7000)

    const onClick = (n: number) => () => {
        setOpenedNumber(n);
        restart()
    }

    return (
        <>
            <SectionTitle title={langDictionary[lang].ADVANTAGES_TITLE} portraitOnly />

            <div className={`adv-title-flex`}>
                <hr style={{ alignSelf: 'start' }} />
                <h1 className="text-white adv-title heading-text">{langDictionary[lang].ADVANTAGES_TITLE}</h1>
                <div className="adv-header">
                    <h1>6{` `}</h1>
                    <h3>{langDictionary[lang].SIX_REASONS_1}<br />{langDictionary[lang].SIX_REASONS_2}<span>SteliOK</span></h3>
                    <hr />
                </div>
            </div>

            <div className="adv-grid">
                <AdvantageSection
                    imgSrc={waterproofImg}
                    title={langDictionary[lang].ADV_6_TITLE}
                    number={1}
                    isOpened={openedNumber !== 0}
                    onClick={onClick(0)}
                    text={langDictionary[lang].ADV_6_TEXT}
                    smallText={langDictionary[lang].ADV_6_SMALL_TEXT} />
                <AdvantageSection
                    imgSrc={ecoMaterialsImg}
                    title={langDictionary[lang].ADV_1_TITLE}
                    number={2}
                    isOpened={openedNumber !== 1}
                    onClick={onClick(1)}
                    text={langDictionary[lang].ADV_1_TEXT}
                    smallText={langDictionary[lang].ADV_1_SMALL_TEXT} />
                <AdvantageSection
                    imgSrc={lowestPricesImg}
                    title={langDictionary[lang].ADV_3_TITLE}
                    number={3}
                    isOpened={openedNumber !== 2}
                    onClick={onClick(2)}
                    text={langDictionary[lang].ADV_3_TEXT}
                    smallText={langDictionary[lang].ADV_3_SMALL_TEXT} />
                <AdvantageSection
                    imgSrc={certifiedImg}
                    title={langDictionary[lang].ADV_4_TITLE}
                    number={4}
                    isOpened={openedNumber !== 5}
                    onClick={onClick(5)}
                    text={langDictionary[lang].ADV_4_TEXT}
                    smallText={langDictionary[lang].ADV_4_SMALL_TEXT} />
                <AdvantageSection
                    imgSrc={perfectSurfaceImg}
                    title={langDictionary[lang].ADV_5_TITLE}
                    number={5}
                    isOpened={openedNumber !== 4}
                    onClick={onClick(4)}
                    text={langDictionary[lang].ADV_5_TEXT}
                    smallText={langDictionary[lang].ADV_5_SMALL_TEXT} />
                <AdvantageSection
                    imgSrc={anyComplexityImg}
                    title={langDictionary[lang].ADV_2_TITLE}
                    number={6}
                    isOpened={openedNumber !== 3}
                    onClick={onClick(3)}
                    text={langDictionary[lang].ADV_2_TEXT}
                    smallText={langDictionary[lang].ADV_2_SMALL_TEXT} />
            </div>
        </>
    )
}

export default Advantages;