import React, { createRef, useContext, useEffect, useRef, useState } from "react";
import { Waypoint } from 'react-waypoint';
import { CSSTransition, TransitionGroup } from "react-transition-group";

import './DiscountAnimations.css'
import './Discount.css'
import langDictionary from "../../../constants/uaLocal.tsx";
import LangContext from "../Contexts/LangContext.tsx";


const Circle = ({ number, show }: { number: number, show: boolean }) => {
    const lang = useContext(LangContext)
    const [rooms, setRooms] = useState<number>(number + 2)
    const [percent, setPercent] = useState<number>((number + 2) * 5);
    const divRef = useRef<HTMLDivElement>(null)
    const pRef = useRef<HTMLParagraphElement>(null)

    useEffect(() => {
        setRooms(number + 2)
        setPercent((number + 2) * 5)
    }, [number])

    const circleClassNames = 'circle circle-' + (number + 1)
    const roomClassNames = `room room-${number + 1}`
    return (
        <>
            <div style={{ display: "none" }} className="cicrle-enter c" />

            <CSSTransition in={show} timeout={1000 + 250 * number} classNames={'circle'} nodeRef={divRef}>
                <div className={circleClassNames} ref={divRef}>
                    <h1>
                        {percent}%
                    </h1>
                </div>
            </CSSTransition>
            <CSSTransition in={show} timeout={3500 + 500 * number} classNames={'room'} nodeRef={pRef}>
                <p className={roomClassNames} ref={pRef}>
                    {rooms}{langDictionary[lang].DISCOUNT_ROOM}
                </p>
            </CSSTransition>
        </>
    )
}
const Discount = () => {
    const discountRef = useRef<HTMLDivElement>(null)
    const [isVisible, setVisible] = useState<boolean>(false)
    const lang = useContext(LangContext)

    return (
        <>
            <h1 className="discount-title">{langDictionary[lang].DISCOUNT_TITLE}</h1>
            <Waypoint bottomOffset={"30%"} onEnter={() => setVisible(true)}>
                <div className="discount-circle-holder" ref={discountRef}>
                    {Array(4).fill(undefined).map((el, i) => <Circle number={i} key={i} show={isVisible} />)}
                    {isVisible &&
                        <>
                            <svg className="discount-landscape" viewBox={`0 0 404 354`} style={{ height: '80vh', margin: '0 auto' }} xmlns="http://www.w3.org/2000/svg">
                                <path strokeWidth={3} d=" M 2 352 L 2 167 A 50 50 0 0 1 102 167 L 102 187 A 50 50 0 0 0 202 187 L 202 167 A 50 50 0 0 1 302 167 L 302 187 A 50 50 0 0 0 402 187 L 402 2" />
                            </svg>
                            <svg className="discount-portrait" viewBox={`0 0 354 404`} style={{ width: '100%' }} xmlns="http://www.w3.org/2000/svg">
                                <path strokeWidth={3} d=" M 354 2 L 167 2 A 50 50 0 0 0 167 102 L 187 102 A 50 50 0 0 1 187 202 L 167 202 A 50 50 0 0 0 167 302 L 187 302 A 50 50 0 0 1 187 402 L 0 402" />
                            </svg>
                        </>
                    }

                </div>
            </Waypoint>
        </>
    )
}

export default Discount;