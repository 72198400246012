import React from "react";

import CustomNavbar from "./Scratch/CustomNavbar/CustomNavbar.tsx";
import Main from "./Scratch/Main/Main.tsx";
import WhyWithUs from "./Scratch/WhyWithUs/WhyWithUs.tsx";
import OurExamples from "./Scratch/OurExamples/OurExamples.tsx";
import AboutUs from "./Scratch/AboutUs/AboutUs.tsx";
import Certificates from "./Scratch/Certificates/Certificates.tsx";
import Materials from "./Scratch/Materials/Materials.tsx";
import Calculator from "./Scratch/Calculator/Calculator.tsx";
import FunctionsContext from "./Scratch/Contexts/FunctionsContext.tsx";
import Specials from "./Scratch/Specials/Specials.tsx";
import Advantages from "./Scratch/Advantages/Advantages.tsx";
import Feedback from "./Scratch/Feedback/Feedback.tsx";
import Steps from "./Scratch/Steps/Stesp.tsx";
import Faq from "./Scratch/FAQ/Faq.tsx";
import Discount from "./Scratch/Discounts/Discounts.tsx";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, useParams, /*useParams*/ } from "react-router-dom";
import LangContext from "./Scratch/Contexts/LangContext.tsx";
import Contacts from "./Scratch/Contacts/Contacts.tsx";
import Footer from "./Scratch/Footer/Footer.tsx";


const Holding = () => {
    const { lang } = useParams<{ lang: 'ua' | 'ru' }>()
    return <>
        <LangContext.Provider value={lang ?? 'ua'}>
            <FunctionsContext.Provider value={{
                disableScroll: () => { document.body.style.overflow = 'hidden' },
                enableScroll: () => { document.body.style.overflow = 'auto' }
            }}>
                <div id="home" />
                <CustomNavbar />
                <Main />
                <Discount />
                <WhyWithUs />
                <OurExamples />
                <AboutUs />
                <Certificates />
                <Materials />
                <Calculator />
                <Specials />
                <Advantages />
                <Feedback />
                <Steps />
                <Faq />
                <Contacts />
                <Footer />
            </FunctionsContext.Provider>
        </LangContext.Provider>
    </>
}



const router = createBrowserRouter(createRoutesFromElements(
    <Route
        path="/"
        element={<Holding />}>
        <Route path='/:lang'
            element={<Holding />} />
    </Route>
))

const App = () => {
    return <RouterProvider router={router} />
}

export default App