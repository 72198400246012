import React, { useContext } from "react";

import './Slider.css'
import { useSliderStore } from "../../../storage/slider.ts";
import LangContext from "../Contexts/LangContext.tsx";
import langDictionary from "../../../constants/uaLocal.tsx";



const Slider = () => {
    const { images, selectedIndex, maxIndex } = useSliderStore()

    const lang = useContext(LangContext)
    return <div className="slider">
        {images.map((img, i) => {
            let classNames = "carusel-img"
            let style: React.CSSProperties = {};
            if ((selectedIndex === ((i + 1) % (maxIndex + 1)))) {
                classNames += ' next-image'
                style = { zIndex: -2 }
            }
            else if (selectedIndex === i) {
                classNames += " curr-image"
                style = { zIndex: -2 }
            }
            else if (((selectedIndex + 1) % (maxIndex + 1)) === i) {
                classNames += ' prev-image'
                style = { zIndex: -3 }
            }
            else if (((selectedIndex + 2) % (maxIndex + 1)) === i) {
                classNames += ' prev-image'
                style = { zIndex: -4 }
            }
            else {
                classNames += ' d-none'
            }

            return (
                <img className={classNames} style={style} src={img} key={i} alt={`example${i}`} />)
        }
        )}
        <h1 className="text-white slider-text">{langDictionary[lang].MAIN_SLIDER_TEXT}</h1>
    </div>
}

export default Slider;